@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400&family=Inter:wght@800&family=Signika&family=Alice:wght@100;200;600;700;800&family=Alice:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Coiny&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');

.hide-btn2 {
    display: none;
}

.hide-btn1 {
    display: block;
}

.form-control:placeholder-shown {
    font-size: 15px;
    font-family: 'Alice';
}

.mobilemenu a{
    font-family: 'Alice';
    padding-bottom: 10px !important;
    color: #fff !important;
    text-transform: uppercase;
}

.mobilemenu {
    font-family: 'Alice';
    font-size: 13px;
    letter-spacing: -0.5px;
    padding-bottom: 10px !important;
    /* text-shadow: 8px 8px 5px rgba(0, 0, 0, 0.719); */
    color: #fff !important;
    text-transform: uppercase;
    white-space: nowrap;

}

/* Define the animation */
@keyframes zoomInOut {
    0% {
        transform: scale(1.5);
        /* Start with 1.5x zoom */
    }

    100% {
        transform: scale(1);
        /* End with normal size */
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        /* Start above its normal position */
        opacity: 0;
        /* Start fully transparent */
    }

    100% {
        transform: translateY(0);
        /* End at its normal position */
        opacity: 1;
        /* End fully opaque */
    }
}

.submobilemenu {
    font-family: 'Alice';
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 10px !important;
    text-shadow: 8px 8px 5px rgb(0, 0, 0);
    color: rgb(255, 255, 255) !important;
    padding-left: 20px;
    letter-spacing: 1px;
    animation: slideInFromTop 0.7s ease forwards;
}

.submobilemenu::before {
    content: " . ";
    font-weight: 900;
    padding-right: 10px;
}


.mblmenuhead {
    font-family: 'Alice';
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid rgba(255, 255, 255, 0.347);


}


.mblnavmain {
    background: none
}
.white_space_wrap{
    white-space: nowrap;
}
@media screen and (max-width:990px) {
    .description_menus{
        overflow: scroll;
    }
    .description_menus li {
   font-style: 13px;
    }
    .white_space_wrap{
        white-space: wrap;
    }
    .mblnavmain {
        /* background-image: linear-gradient(rgba(0, 0, 0, 0.855), rgba(0, 0, 0, 0.721)), url('https://img.freepik.com/free-photo/top-view-fruits-basket-bunches-grapes-persimmons-apples-with-leaves_140725-119812.jpg'); */
        background-repeat: no-repeat;
        background-size: cover;
    background-color: #172E00;
    }
}

.font-1 {
    font-family: 'Alice';
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #FFFFFF;
    letter-spacing: 0em;
    text-align: left;
    background-color: #629A28;
    width: 156px;
    height: 56px;
    top: 410px;
    left: 174px;
    padding: 16px 36px;
    /* Top, Right, Bottom, Left */
    gap: 10px;
    margin-top: 50px;

}

.font-11 {
    border-radius: 3px;
    font-family: 'Alice';
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #FFFFFF;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    background-color: #629A28;
    width: 136px;
    height: 56px;
    top: 410px;
    left: 174px;
    padding: 16px 28px;
    /* Top, Right, Bottom, Left */
    gap: 10px;

}

.first-btn {
    margin-left: 45px;

}



.font-2 {
    font-family: 'Alice';
    font-size: 2.125rem;
    ;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 6px !important;
    text-align: center;
    white-space: nowrap;
    /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.437), /* Light shadow 
    2px 2px 4px rgba(0, 0, 0, 0.184);  Slightly darker shadow */
}

.font-3 {
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Alice;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: nowrap;
    padding-top: 10px;
}

.font-4 {

    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Alice;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
    padding-top: 20px;
}

.font-head {
    color: #629A28;
    font-family: 'LT-TM-021';
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
}

.font-5 {
    font-family: 'Alice';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;

}

.font-6 {
    flex-shrink: 0;
    color: #121212;
    padding-top: 20px;
    font-family: Alice;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.552px;
    text-transform: uppercase;
}

.teststar .font-7 {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #666666;


}

.main-container {
    overflow: hidden;
}


/* Button styles */
.effect-hover2 {
    margin: 0px auto;
    margin-top: 20px;
    display: flex;
    width: 80%;
    height: 40px;
    padding: 0px 5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: none;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    color: black;
    color: #000;
    font-family: Alice;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
    text-transform: uppercase;
}

.blend-lighter {
    mix-blend-mode: plus-lighter;
    background-color: #000;
    /* Add a background for testing */
    position: relative;
}

.product_main_box {
    border-radius: 16px;
    border: 1px solid rgb(0 0 0 / 12%);
    background: var(--Primary-Base-White, #FFF);
    overflow: hidden;
}


.home_pro_name {
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Alice";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 20px;

}

.home_pro_name2 {
    color: #000000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Alice";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 20px;

}

.noreshimentimg {
    position: absolute;
    top: -115px;
    z-index: 1000;
    width: 249px;
    right: 34px;
}

.effect-hover3:hover {
    background: linear-gradient(0deg, #ffffff71, rgb(98, 154, 40));
    /* Gradient between initial and final color */
    background-size: 100% 200%;
    /* Background size */
    transition: background-position 0.6s;
    /* Smooth transition for background position */
    animation: colorScroll3 1s infinite alternate;
    /* Apply the animation */
}

.Frisa-font{
    color: var(--Primary-Base-White, #FFF);
    -webkit-text-stroke-width: 0.5;
    -webkit-text-stroke-color: var(--Primary-Base-White, #FFF);
    font-family: Alice;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 75% */
    padding-bottom: 15px;
}
.Frisa-font2 {
    color: var(--Primary-Base-White, #FFF);
    font-family: Alice;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 100% */
}

@keyframes colorScroll3 {
    0% {
        background-position: 0 0;
        /* Initial background position */
    }

    100% {
        background-position: 0 100%;
        /* Final background position */
    }
}

@keyframes colorScroll2 {
    0% {
        background-position: 0 0;
        /* Initial background position */
    }

    100% {
        background-position: 0 100%;
        /* Final background position */
    }
}

.effect-hover {
    display: flex;
    width: 100px;
    height: 40px;
    padding: 0px 5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: none;
    border-radius: 5px;
    background: rgb(98, 154, 40);
    color: #FFF;
    text-align: center;
    font-family: 'Alice';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    cursor: pointer;
    outline: none;
    transition: background 0.5s;
    /* Smooth transition for background color */
}

/* Hover effect */
@keyframes colorScroll {
    0% {
        background-position: 0 0;
        /* Initial background position */
    }

    100% {
        background-position: 0 100%;
        /* Final background position */
    }
}

/* Hover effect with animation */


.sidemenuitems {
    color: #629A28;
}

.Header-input {
    height: 48px !important;
    padding: 15.5px 20.5px 15.5px 20px !important;
    border-radius: 4px 0px 0px 4px !important;
}

.Header-input::placeholder {
    font-family: 'Alice' !important;
    font-size: 14px !important;
    letter-spacing: 0em !important;
}

.Header-input2::placeholder {
    font-family: 'Alice';
    font-size: 14px;
    letter-spacing: 0em;
}

.Header-input2 {
    width: 100% !important;
    height: 48px !important;
    padding: 15.5px 20.5px 15.5px 20px !important;
    border-radius: 4px 0px 0px 4px !important;

}

.Header-btn {
    border-radius: 0px 4px 4px 0px !important;
    background-color: #629A28 !important;
    border: none !important;

}

.snack {
    height: fit-content;

}

.mySwiper1 .inside-slider {
    height: auto;
    /* width: 100% !important; */
}

.mySwiper1 {
    height: fit-content;
}

.mySwiper1 .inside-slider p {
    font-family: 'Alice';
    color: #121212;
    text-align: center;
    padding-top: 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}


.Header-btn2 {
    width: 50px !important;
    border-radius: 0px 4px 4px 0px !important;
    background: #629A28 !important;
    border: none !important;

}


/* Header :end */


.btn-add {
    position: relative;
    border-radius: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 50px;
    z-index: 1;
}

.btn-add {
    background-color: rgba(98, 154, 40, 1);
    border-radius: 100%;
}

.mySwiper2 {
    position: relative;

    background: #F8F6EC;


}
.Product_ImageGallery .image-gallery-thumbnails-wrapper {
    width: 25%; /* Adjust this value to increase the left thumbnail section */
  }
  
  .Product_ImageGallery .image-gallery-slide-wrapper {
    width: 75%; /* Adjust this to balance the main image area */
  }
  

/* Customize the color of the pagination bullets */
.mySwiper2 .swiper-pagination-bullet {
    background-color: #000000;
    /* Change this to your desired color */
    width: 15px;
    /* Adjust the width if needed */
    height: 15px;
    /* Adjust the height if needed */
    margin: 0 5px;
    /* Adjust the margin if needed */
}

/* Optionally, you can customize the active bullet color */
.mySwiper2 .swiper-pagination-bullet-active {
    background-color: #629A28;
    /* Change this to your desired active color */
}


/* Customize the color of the pagination bullets */
.mySwipper7 .swiper-pagination-bullet {
    background-color: #071C1F;
    ;
    /* Change this to your desired color */
    width: 35px;
    /* Adjust the width if needed */
    height: 6px;
    /* Adjust the height if needed */
    margin: 0 5px;
    border-radius: 0px;
    /* Adjust the margin if needed */
}

/* Optionally, you can customize the active bullet color */
.mySwipper7 .swiper-pagination-bullet-active {
    background-color: #86BC42;
    /* Change this to your desired active color */
}


.myswiper6 {
    position: relative;
}

.add-btn {

    background: #629A28;
}

.mySwiper2 {
    height: fit-content;
}

.mySwiper2 .btns {
    background-color: rgba(98, 154, 40, 1);
    border-radius: 100%;
}

.Shop-btn {
    display: inline-flex;
    padding: 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: rgba(98, 154, 40, 1);
    color: #D9D9D9;
    border-radius: 0px;
}

.secban2 {
    height: 50%;
}


.mySwiper3 {
    overflow: hidden;
    color: #FFF;
    font-family: Alice;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 133.333% */
    letter-spacing: -0.5px;
}

.ban1con {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
}

.mySwiper3 img {
    border-radius: 20px;
}



.mini-addCon-1 {
    background: #629A28;
    color: #ffffff;
    font-family: Alice;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
    border-radius: 5px;
    margin: 10px;
    height: 268px;

}

.view-btn {
    width: 132.19px;
    height: 40px;
    top: 568px;
    left: 121px;
    padding: 0px, 36.19px, 0px, 36px;
    border-radius: 5px;
    background: #629A28;
    font-family: 'Alice';
    font-size: 15px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;



}

.mini-addCon-1 .btn {
    border-radius: 5px;
    background: #FFF;
    width: 132.19px;
    height: 40px;
    flex-shrink: 0;
}

.mini-addCon-2 .btn {
    border-radius: 3.429px;
    background: #629A28;
    width: 132.19px;
    height: 40px;
    flex-shrink: 0;
    color: white;
}



.testpro {
    color: rgb(0, 0, 0);
    position: relative;
    top: 40px;
    z-index: 1;
    font-family: 'Alice';
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

}

.teststar {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 12.650469779968262px 25.300939559936523px 0px #3D3D3D1A;
    width: 70px;
    padding: 7px;
}

.teststar p {
    font-family: 'Alice';
    font-size: 13px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;

}

.banner {
    background-image: url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/thaithingal/HomepageImages/Signin-img.webp");
}

.mini-addCon-2 {
    background: #F5F5F5;
    border-radius: 5px;
    margin: 10px;
    color: #000000;
    font-family: 'Alice';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
height: 268px;
}

.mySwiper9 {
    height: fit-content;
    position: relative;
}

.mySwiper9 .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    color: #000000 !important;
    font-size: 20px;
}

.mySwiper9 .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    color: #000000 !important;
    font-size: 20px;
}

.mini-addCon-2 .btn-2 {
    border-radius: 5px;
    background: #629A28;
    border-radius: 5px;
    color: #F5F5F5;
    width: 132.19px;
    height: 40px;
    flex-shrink: 0;
}



/* Customize the appearance of the next button */
.swiper-button {
    background-color: #86BC42;
    border-radius: 100%;

}

.badgecode span {
    background: #306800 !important;
    /* background: #306800; */
    padding: 12px;
    gap: 10px;
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "Alice";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 0px;
    /* 0% */
    border-radius: 4px;

}

.badgecode {
    display: flex;
    justify-content: end;

}

.badgecode2 span {
    background: rgba(98, 154, 40, 0.60);
    /* background: #306800; */
    padding: 12px;
    gap: 10px;
    color: #000000;
    leading-trim: both;
    text-edge: cap;
    font-family: "Alice";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 0px;
    /* 0% */
    border-radius: 4px;

}

.badgecode2 {
    display: flex;
    justify-content: end;

}

.swiper-button-ri {
    background-color: #86BC42;
    border-radius: 100%;

}

.offer {
    padding: 15px;
    position: absolute;
    width: fit-content;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 13.793px 0px 0px 0px;
    background: #86BC42;
    color: #FFF;
    top: 0;
    z-index: 100;
}

/* Stanck SEction :start */
.Snack-section {
    background: #F5F5F5;
    position: relative;
}

.mySwiper4 {
    overflow: hidden;
}

.btn-click {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 200px;
    z-index: 1;
}

.mySwiper4 .swiper-wrapper .swiper-slide .card {

    /* width: 232px; */
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    border: none;
}

/* Snack Section End */
/* Baby SEcdtion :start */

.baby-section .card {
    border: none;
    border-radius: 12.96px;
    box-shadow: none;
}

.baby-section img {
    border-radius: 12px 12px 0px 0px;
}

.baby-section .card-title {
    color: #121212;
    text-align: center;
    font-family: 'Alice', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    text-transform: uppercase;
}

/* Baby Section :end */

/* shop image :start */
.shop-img img {
    border-radius: 24px;
}

.shop-section {
    background: #F9F9F9;
}

/* top image :end */
.mini-ban {
    height: 148.59px;
    gap: 14.8px;
    flex-shrink: 0;
    border: 1px dashed #86BC42;
    background: #FAFAEB;

}

.Delivery-img {
    position: relative;
    top: 38px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    border-radius: 12px;
    border: 2px solid #629A28 !important;
    overflow: hidden;
}

.image-gallery-thumbnail {
    border-radius: 12px;
    overflow: hidden;

}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
    border-radius: 12px;
    border: 2px solid #629A28 !important;
    overflow: hidden !important;
}

.image-gallery-slide .image-gallery-image {
    border-radius: 12px !important;
}


/* Testimonials :start */
.mySwiper5 {

    position: relative;

}

.Testimonials {
    height: fit-content;
}

.Testimonials .swiper-sli {
    border: none;
    box-shadow: 0px 16.867292404174805px 25.300939559936523px 0px #9A9A9A1A;

}

/* Testimonials :end */
/* Bannner :start */
.Banner-2 {
    overflow: hidden;
    background: #86BC42;
}

/* Banner :end */
/* SERVICES :start */
.services {
    background: #86BC42;
}

/* SERVICES :end */
/* footer1 :start */
.footer1 {
    background: #86BC42;
    padding: 20px;
    color: #FFF;
    font-family: Alice;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px;
    /* 160% */
}

.footer1 ul li {
    color: #FFFFFF;
    font-family: 'Alice', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.08px;
    /* 172% */
    list-style: none;
}

.footer1 div a {
    color: #FFFFFF;
    font-family: 'Alice', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.08px;

    list-style: none;
}

.footer1 ul li a {
    color: #FFFFFF;
    font-family: 'Alice', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.08px;
    /* 172% */
    list-style: none;
}

.footer1 ul h4 {
    color: #FFFFFF;
    font-family: 'Alice';
    font-weight: 500;
    line-height: 18px;
    /* 100% */
    text-transform: uppercase;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 100% */
    text-transform: uppercase;
    padding-bottom: 20px;
}

.overflowscrol {
    overflow: hidden;
    /* Hide the scrollbar */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.overflowscrol::-webkit-scrollbar {
    display: none;
}

.suveymsg {
    font-size: 13px;
    padding-bottom: 20px;
    font-weight: 700;
    line-height: 30px;
}

.animated-text {
    display: inline-block;
}

.animated-text {
    display: inline-block;
    animation: jump 2s ease-in-out infinite;
    /* Slower and smoother animation */
}

.getexcitingnews {
    color: #000000;
    position: fixed;
    bottom: 86px;
    right: 10px;
    z-index: 1000;
    font-size: 12px;
    border-radius: 50%;
    /* Use 50% for a perfect circle */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: transform 0.3s ease;
    /* Optional: Add transition for a smoother appearance */
}

.getexcitingnews span {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Add some shadow for better visibility */
    background-color: white;
    font-weight: 600;
    text-align: center;
    padding: 5px 15px;
    border-radius: 40px;
    color: #333;
    /* Change text color */
    font-size: 13px;
    /* Adjust font size */
    transition: all 0.3s ease-in-out;
    /* Smooth transition for hover effect */
}

.getexcitingnews span:hover {
    background-color: #f0f0f0;
    /* Slightly darker background on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    /* Stronger shadow on hover */
    transform: scale(1.05);
    /* Slightly increase size on hover */
}

.getexcitingnews span:active {
    background-color: #e0e0e0;
    /* Even darker background on active */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Reset shadow on active */
    transform: scale(1);
    /* Reset scale on active */
}


@keyframes rotate360Horizontal {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}

.getexcitingnews img {
    margin: 5px 0;
    /* Add margin to space out the image and text */
    animation: rotate360Horizontal 5s linear infinite;
    transform-origin: center;
    /* Ensure the rotation occurs around the center of the image */
    perspective: 1000px;
    /* Add perspective for the 3D effect */
}



@keyframes jump {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-2px);
    }
}



p {
    color: #FFFFFF;
    font-family: 'Alice', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.08px;
    /* 172% */
    list-style: none;
}

/* Footer1 :end */
.footer1 .heading {
    color: #FFFFFF;
    font-family: 'Alice', sans-serif;
    font-weight: 600;

}


a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}



footer span {
    color: #80B500;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.38px;
}

.navbarmain {
    border-bottom: 1px solid #EBEBEB;
    background-color: white;

}

p {
    color: black;
}

.main-ban {
    height: 600px;

}

.ban-2 {
    height: 100%;
}

.ban-22 {
    border-radius: 5px;
    position: relative !important;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    background: #ECFFD9 url("../Images/thaithingal_bg.png") no-repeat bottom/contain;
    margin-bottom: 11px;
    margin-top: 11px;
}



/* Fotter :end */
.vegimg {
    height: 200px;
    background-color: #121212;
}

.postion {
    position: absolute;
    bottom: 0;
}


.second-tamil h1 {
    font-family: 'Alice';
    font-size: 35px;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: left;
    min-height: 50px;

}

.second-tamil p {
    font-family: 'Alice';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 90%;

}

.parampariya {
    font-family: 'Alice';
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;

}


.parampariya p {
    font-family: 'Alice';
    font-size: 60px;
    font-weight: 1000;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
    color: #444242;
}

.iphones {
    filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.726));
}

.thaithin-head {
    color: #FFF !important;
    font-family: 'Alice' !important;
    font-size: 65px !important;
    font-style: normal !important;
    font-weight: 700 !important;
}


.line-dum {
    width: 100px;
    height: 4px;
    border-radius: 5px;
    background-color: #A4B660;
}

.para-font {
    color: #3A3A3A;
    text-align: center;
    font-family: 'Alice', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.08px;
}

.para-font2 {
    color: #3A3A3A;
    text-align: center;
    font-family: 'Alice', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.price {
    color: #000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Alice";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px; /* 229.167% */
    text-transform: uppercase;

}

.pricemrp {
    color: #000;
text-align: center;
leading-trim: both;
text-edge: cap;
font-family: "Alice";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 15px; /* 100% */
text-decoration-line: strikethrough;
text-transform: uppercase;
opacity: 0.9;

}

.tamiltext {
    font-family: 'Alice';
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;

}

.footercontents {
    display: flex;
    justify-content: space-between;
}
.whatsapp_order{
        color: #41544A ;
        text-align: center;
        font-family: "Alice";
        font-size: "20px";
        font-style: "normal";
        font-weight: 500;
        line-height: "41.28px";
        
}
@media screen and (max-width: 1300px) {
    .second-tamil h1 {
        font-size: 26px;
        line-height: 34px;
    }
    .second-tamil p {
        font-style: 15px !important; 
    }
    .mini-addCon-1 {
   height: fit-content;
    }
    .hugesale_section h2{
        font-size: 35px !important;
        white-space: nowrap;

    }
    .hugesale_section a{
        padding: 8px 27px !important;
        white-space: nowrap;

    }
    .hugesale_section p {
font-size: 15px !important;
    }
    .mini-addCon-2 {
        height: fit-content;
         }
    .hugesale_section_2 h2{
        font-size: 35px !important;
        white-space: nowrap;

    }
    .hugesale_section_2 a{
        padding: 8px 27px !important;
        white-space: nowrap;

    }
    .hugesale_section_2 p {
font-size: 15px !important;
    }
}
@media screen and (max-width: 1100px) {
    .Frisa-font{
        color: var(--Primary-Base-White, #FFF);
-webkit-text-stroke-width: 0.4426342248916626;
-webkit-text-stroke-color: var(--Primary-Base-White, #FFF);
font-family: Alice;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 10.623px; /* 70.821% */
padding-bottom: 15px;
    }
    .Frisa-font2 {
        color: var(--Primary-Base-White, #FFF);
        font-family: Alice;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px /* 108.956% */
    }
    .Delivery-img{
        width: 100px;
        height: 69px;
        transform: rotate(-6.763deg);
        flex-shrink: 0;
      }
      

    .whatsapp_order{
        color: #000;
        text-align: center;
        font-family: Alice;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 116.667% */
        padding-bottom: 20px;
    }
    .whatsapp_order a{
        margin-top: 10px;
        color: #393B40;
text-align: center;
-webkit-text-stroke-width: 0.5;
-webkit-text-stroke-color: #393B40;
font-family: Alice;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 118.182% */
    }
    .Header-input2 {
        padding: 15.5px 20.5px 15.5px 20px !important;
        border-radius: 4px 0px 0px 4px !important;

    }

    .inputshow {
        width: 100%;
    }

    .parampariya p {
        font-size: 35px
    }

    .font-4 {
        font-family: 'Alice';
        font-size: 19px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.5px;
        text-align: left;
        padding-bottom: 10px;

    }

}

.footericons {
    display: flex;
}

.hugesale_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hugesale_section span {
    border-radius: 4px;
    background: #306800;
    display: inline-flex;
    padding: 13px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Primary-Base-White, #FFF);
    leading-trim: both;
    font-family: "Alice";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 0px; /* 0% */
    text-transform: uppercase;
    text-align: end;
    align-self: flex-end;
    margin-right: 10px;
}
.hugesale_section h2{
    color: var(--Primary-Base-White, #FFF);
-webkit-text-stroke-width: 0.800000011920929;
-webkit-text-stroke-color: var(--Primary-Base-White, #FFF);
font-family: Alice;
font-size: 46px;
font-style: normal;
font-weight: 600;
line-height: 48px; /* 92.308% */
padding-bottom: 20px;
}
.hugesale_section p{
color: var(--Primary-Base-White, #FFF);
text-align: center;
leading-trim: both;
text-edge: cap;
font-family: Alice;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 3px;
text-transform: uppercase;
padding-bottom: 24px;
}
.hugesale_section a{
    text-transform: uppercase;
    display: flex;
padding: 16px 31px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: var(--Primary-Base-White, #FFF);
color: #000;
font-family: Alice;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
text-transform: uppercase;
}

.hugesale_section_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hugesale_section_2 span {
    border-radius: 4px;
    background: rgba(98, 154, 40, 0.60);
    display: inline-flex;
padding: 13px;
justify-content: center;
align-items: center;
gap: 10px;
color: #172E00;
leading-trim: both;
text-edge: cap;
font-family: "Alice";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 0px; /* 0% */
align-self: flex-end;
margin-right: 10px;
}
.hugesale_section_2 h2{
    color: #172E00;
    leading-trim: both;
    text-edge: cap;
    -webkit-text-stroke-width: 0.800000011920929;
    -webkit-text-stroke-color: #1A3400;
    font-family: Alice;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; 
    padding-bottom: 20px;
}
.hugesale_section_2 p{
    color: #172E00;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Alice;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding-bottom: 24px;
}
.hugesale_section_2 a{
    display: flex;
    padding: 16px 29.165px 16px 32.835px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
background: #629A28;
color: var(--Primary-Base-White, #FFF);
font-family: Alice;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
}
.Snack-card {
    box-shadow: none;
    border-radius: 13.97px;
    border: 1px solid rgb(0 0 0 / 12%);
    height: fit-content;
}



@media screen and (max-width: 990px) {

    
    .hugesale_section_2 span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 5.805px;
        border-radius: 2.322px;
        background: rgba(98, 154, 40, 0.60);
color: #172E00;
leading-trim: both;
text-edge: cap;
font-family: "Fira Sans";
font-size: 10.449px;
font-style: normal;
font-weight: 500;
line-height: 0px; /* 0% */
    align-self: flex-end;
    margin-right: 0px;
    }
    .hugesale_section span{
        display: inline-flex;
justify-content: center;
align-items: center;
gap: 5.805px;
border-radius: 2.322px;
background: #306800;
color: #FFF;
leading-trim: both;
text-edge: cap;
font-family: "Fira Sans";
font-size: 10.449px;
font-style: normal;
font-weight: 500;
line-height: 0px; 
margin-right: 0px;
    }
    .image-gallery-thumbnails .image-gallery-thumbnails-container{
        text-align: left !important;
        margin-top: 1px !important;
    }
    .ban-22 {
        border-radius: 5px;
        position: relative !important;
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        background: #ECFFD9;
        margin-bottom: 11px;
        margin-top: 11px;
    }
    .mini-addCon-1 {
        height: fit-content;
    }
    .hugesale_section h2{
        font-size: 26px;
        padding-bottom: 5px;
    }
    .hugesale_section p{
        font-size: 16px;
        padding-bottom: 15px;
    }
    .home_pro_name {
        padding-bottom: 20px;
        font-size: 9px !important;
    }
    .hugesale_section a{
        padding: 9.288px 13.778px 9.22px 13.779px !important;
        font-size: 12px;
    }
    .hugesale_section_2 h2{
        font-size: 26px;
        padding-bottom: 5px;
    }
    .hugesale_section_2 p{
        font-size: 16px;
        padding-bottom: 15px;
    }
    .home_pro_name {
        padding-bottom: 20px;
        font-size: 9px !important;
    }
    .hugesale_section_2 a{
    padding: 7px 30px;
    font-size: 13px;
    }

    .home_pro_name2 {
        padding-bottom: 15px;
        font-size: 9px !important;
    }

    .noreshimentimg {
        position: relative;
        top: 0;
        right: 0px;
        left: 30px;
    }

    .footericons {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .effect-hover2 {
        font-size: 12px;
    }

    .font-3 {
        font-size: 12px;
        letter-spacing: 2px;
    }

    .font-2 {
        font-size: 26px;
        letter-spacing: 2px !important;
    }

    .tamiltext {
        font-family: 'Alice';
        font-size: 10px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.5px;
        /* text-align: center; */
        text-transform: uppercase;

    }

    .subBtn {
        width: 30% !important;
        font-size: 12px !important;
        font-weight: 700 !important;
    }

    .hide-btn2 {
        display: block;
    }

    .hide-btn1 {
        display: none;
    }

    .second-tamil p {
        font-size: 12px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;

    }

    .footercontents {
        align-items: center;
        text-align: center;
        display: block;
        justify-content: center;
    }

    .footercontents p {
        font-size: 13px;
    }

    .first-btn {
        margin-left: 0px;
        display: flex;
        justify-content: center;
    }

    .ban1con {
        display: block;
        justify-content: center;
        position: relative;
    }

    .span-hide {
        display: none;
    }

    .iphones {
        display: none;
    }

    .price {
        font-size: 16px;
    }

    .pricemrp {
        font-size: 14px;
    }

    .Header-input {
        display: none !important;
    }

    .inputshow {
        width: 100%;
    }

    .Header-btn {
        display: none !important;
    }

    .mySwiper1 .inside-slider p {
        padding-top: 10px;
        font-size: 9px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: center;

    }

    .font-5 {
        font-family: 'Alice';
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 1px;
        text-align: center;

  padding: 2px 4px ;
    }

    .thaithin-head {
        color: #FFF !important;
        font-family: 'Alice' !important;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        text-align: center !important;
    }

    .Banner-2 p {
        font-size: 12px !important;
        line-height: 20px !important;
        text-align: center !important;
    }

    .font-1 {
        width: 116px;
        /* Adjust as needed */
        height: 14px;
        /* Adjust as needed */
        font-size: 8px;
        line-height: 0px;
        letter-spacing: 0em;
        text-align: center;
        background-color: #629A28;


    }

    .font-11 {
        width: 116px;
        /* Adjust as needed */
        height: 14px;
        /* Adjust as needed */
        font-size: 13px;
        line-height: 0px;
        letter-spacing: 0em;
        text-align: center;
        background-color: #629A28;


    }



    .parampariya {
        display: block;
        justify-content: center;
    }


    .parampariya p {
        font-family: 'Alice';
        text-align: center;
        color: #444242;
        font-style: normal;
        font-weight: 1000;
        font-size: 25px;
        line-height: 40px;
        width: auto;
        height: auto;
        top: 50px;
        padding: 10px;
    }

    .imglogo {
        width: 180px;
        margin: 0px auto;
    }

    .logo-brand {
        display: flex;
        justify-content: center;
    }


    .ban-2 {
        height: 100%
    }

    .font-4 {
        font-size: 12px;
        white-space: wrap;
        padding-top: 0px;
    }

    .font-head {
        color: #629A28;
        font-family: 'LT-TM-021';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: -0.5px;
        text-transform: uppercase;
    }


    .font-6 {
        flex-shrink: 0;
        color: #121212;
        padding-top: 20px;
        font-family: Alice;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.552px;
        text-transform: uppercase;
    }

    .mini-addCon-2 {
        font-size: 16px;
    }

    .postion {
        position: relative;
    }

    .offer {
        font-size: 10px;
        position: absolute;
        flex-shrink: 0;
        border-radius: 13.793px 0px 0px 0px;
        background: #86BC42;
        color: #FFF;
    }
    .baby-section .card {
        border: none;
        border-radius: 12.96px;
        box-shadow: none;
        height: fit-content;
    }

    .second-tamil h2 {
        font-size: 28px !important;
        font-weight: 900;
        line-height: 36px;
        letter-spacing: -0.5px;
        text-align: left;

    }

}



/*! CSS Used from: https://www.vilvahstore.com/cdn/shop/t/183/assets/theme.css?v=42047624696055260471718707404 ; media=all */
@media all {

    *,
    :before,
    :after {
        box-sizing: border-box;
        border-style: solid;
        border-width: 0;
        border-color: rgb(var(--text-color) / .5);
    }

    p {
        margin: 0;
    }

    :disabled {
        cursor: default;
    }

    img,
    video {
        vertical-align: middle;
        display: block;
    }

    img {
        color: #0000;
        max-width: min(var(--image-mobile-max-width, var(--image-max-width, 100%)), 100%);
        height: auto;
    }

    video {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    @media screen and (min-width: 700px) {
        img {
            max-width: min(var(--image-max-width, 100%), 100%);
        }
    }

    [role=main] .shopify-section {
        --section-is-first: 0;
        --hash-difference: calc(var(--section-background-hash) - var(--previous-section-background-hash, -1));
        --hash-difference-abs: max(var(--hash-difference), -1 * var(--hash-difference));
        --background-differs-from-previous: max(0, min(var(--hash-difference-abs, 1), 1));
        background: rgb(var(--background));
        position: relative;
    }

    [role=main] .shopify-section:not(.contents) {
        display: flow-root;
    }

    [role=main] .shopify-section:empty {
        display: none;
    }

    .section {
        --context-section-spacing-block-start: var(--section-outer-spacing-block);
        --context-section-spacing-block-end: var(--section-outer-spacing-block);
        --context-section-spacing-inline: var(--container-gutter);
        --calculated-section-spacing-block-start: var(--section-spacing-block-start, var(--section-spacing-block, var(--context-section-spacing-block-start)));
        --calculated-section-spacing-block-end: var(--section-spacing-block-end, var(--section-spacing-block, var(--context-section-spacing-block-end)));
        --calculated-section-spacing-inline: var(--section-spacing-inline, var(--context-section-spacing-inline));
        --container-inner-width: min((100vw - var(--scrollbar-width, 0px)) - var(--calculated-section-spacing-inline, 0px) * 2, var(--container-max-width));
        --container-outer-width: calc(((100vw - var(--scrollbar-width, 0px)) - var(--container-inner-width, 0px)) / 2);
        --section-stack-spacing-inline: var(--section-inner-spacing-inline, 0px);
        padding-inline-start: max(var(--calculated-section-spacing-inline), 50% - var(--container-max-width) / 2);
        padding-inline-end: max(var(--calculated-section-spacing-inline), 50% - var(--container-max-width) / 2);
    }

    .section {
        padding-block-start: calc(var(--background-differs-from-previous) * var(--calculated-section-spacing-block-start));
        padding-block-end: var(--calculated-section-spacing-block-end);
    }
}

/*! CSS Used from: Embedded */
.quinn_video-container video {
    display: block !important;
}

/*! CSS Used from: Embedded */
div.svelte-nq3wdf {
    box-sizing: border-box;
}

div.svelte-nq3wdf:empty {
    display: none !important;
}

/*! CSS Used from: Embedded */
.quinn-cards-stories-carousel-body {
    gap: 0px !important;
}

.quinn-cards-stories-carousel-body>div:last-child {
    margin-right: auto;
}

.quinn-cards-desktop>div:not(:last-child) {
    margin-right: 15px;
}

/*! CSS Used from: Embedded */
div.svelte-y7vkdd {
    box-sizing: border-box;
}

div.svelte-y7vkdd:hover {
    cursor: pointer;
}

.isDragging.svelte-y7vkdd {
    cursor: grabbing !important;
}

/*! CSS Used from: Embedded */
p.svelte-1rxewty {
    margin: 0;
    padding: 0;
}

/*! CSS Used from: Embedded */
div.svelte-1qxcvef {
    display: none;
}

/*! CSS Used from: Embedded */
.quinn_video-container.svelte-jk0m5r.svelte-jk0m5r {
    position: relative;
}

.quinn_video-container.svelte-jk0m5r video.svelte-jk0m5r {
    max-width: 100%;
    max-height: 100%;
}

/*! CSS Used from: Embedded */
[class^="quinn-"]::-webkit-scrollbar,
[class^="quinn_"]::-webkit-scrollbar {
    display: none;
}

[class^="quinn-"] {
    box-sizing: border-box;
}

/*! CSS Used from: Embedded */
#shopify-section-template--15209686138943__featured_collection_tabs_UbXLcT+* {
    --previous-section-background-hash: 0;
}

/*! CSS Used from: Embedded */
#shopify-section-template--15209686138943__1686725104cb64554d {
    --section-background-hash: 0;
}

/*! CSS Used from: Embedded */
.quinn_cards {
    display: flex !important;
    flex-wrap: nowrap;
    overflow: scroll;
    position: relative;
    scrollbar-width: none;
}

.quinn_card_loading {
    display: block !important;
    background-color: var(--quinn_loader_color);
}

.quinn_cards::-webkit-scrollbar {
    display: none;
}

@media (min-width: 501px) {
    #quinn-cards-1.quinn_cards {
        margin: 0;
        padding: 10px;
        scroll-padding: 10px;
    }
}

@media (max-width: 500px) {
    #quinn-cards-1.quinn_cards {
        margin: 0;
        padding: 5px;
        scroll-padding: 5px;
    }
}

.quinn-cards-stories-carousel-body::-webkit-scrollbar {
    display: none !important;
}

.footericons li:nth-child(1) a {
    color: rgb(255, 255, 255) !important;
    transition: color 1s ease;
    /* Smooth transition for color change */
}

.footericons li:nth-child(1) a:hover {
    color: #1877F2 !important;
    /* Color changes to a specific shade on hover */
}

.footericons li:nth-child(2) a {
    color: rgb(255, 255, 255) !important;
    transition: color 1s ease;
    /* Smooth transition for color change */
}

.footericons li:nth-child(2) a:hover {
    color: #000000 !important;
    /* Color changes to a specific shade on hover */
}

.footericons li:nth-child(3) a {
    color: rgb(255, 255, 255) !important;
    transition: color 1s ease;
    /* Smooth transition for color change */
}

.footericons li:nth-child(3) a:hover {
    color: #f734a9 !important;
    /* Color changes to a specific shade on hover */
}

.footericons li:nth-child(4) a {
    color: rgb(255, 255, 255) !important;
    transition: color 1s ease;
    /* Smooth transition for color change */
}

.footericons li:nth-child(4) a:hover {
    color: #f21818 !important;
    /* Color changes to a specific shade on hover */
}


.rating_section{
    display: flex;
    gap: 12px;
    align-items: center;
}
/* Product ifo correction  */
.rating_stars{
    display: flex;
    gap: 4px;
}
.rating_count p{
    white-space:"nowrap" !important;
    color: #000;
font-family: Alice;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 140% */
}
@media screen and (max-width: 990px) {
    .rating_count p{
        color: #000;
        font-family: Alice;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.982px; /* 199.835% */
        white-space: nowrap;
    }
    .rating_star_box{
        gap: 3px !important;

    }
    .rating_star_box svg{
     font-size: 12px;
     height: 18px;
     width: 18px;
    }
    .rating svg{
        width: 13px !important;
        height: 13px !important;
    }
    
}